.modal-fade {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    z-index: 100000;
    visibility: visible;
    opacity: 1;
    // transition: all 0.2s ease-in-out;
    background: rgba(0, 0, 0, 0.86);
    .board {
        position:fixed;
        background-color: transparent;
        padding: 12px;
        border-radius: 5px;
        top:50%;
        left:50%;
        margin-top: -12px;
        transform: translate(-50%,-50%);
        .btn-hide {
            border: none;
            background: transparent;
            float: right;
            margin-top: -22px;
            margin-right: -16px;
            img {
                width: 57px;
                height: 56px;
            }
        }
        .bg-modal {
            background-image: url('../../../assets/images/modal-plate.svg');
            background-repeat: no-repeat;
            width: 328px;
            height: 311px;
            padding-top: 47px;
            .content-modal {
                width: calc(100% - 19px);
                height: 205px;
                overflow-y: auto;
                padding: 0px 18px 15px 40px;
                line-height: 25.5px;
                .content { 
                    font-family: "shinmgopro-heavy";
                    font-size: 14px;
                    color: #00396E;
                }
                .txt-green {
                    font-family: "shinmgopro-heavy";
                    font-size: 14px;
                    color: #00AD93;
                }
                .txt-blue {
                    font-family: "shinmgopro-heavy";
                    font-size: 14px;
                    color: #00CBD2;
                }
                .txt-pink {
                    font-family: "shinmgopro-heavy";
                    font-size: 14px;
                    color:#FF7DCC;
                }
                .txt-title {
                    position: fixed;
                    top: 55px;
                    width: 250px;
                    height: 39px;
                    font-family: "shinmgopro-heavy";
                    color: #FF7B05;
                    font-size: 20px;
                    background-color: #DEE7FF;
                }
            }
        }
        .bg-modal-cookie {
            .content-modal {
                padding-top: 5px;
                height: 210px;
            }
        }
    }
}
#style-scroll {
    -webkit-overflow-scrolling: auto;
}
#style-scroll::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: #B0BBFF;
    border: 4px solid transparent;
    background-clip: content-box;
}
#style-scroll::-webkit-scrollbar {
    width: 16px;
    border-radius: 100px;
    background-color: #DEE7FF;
    box-shadow: inset 2px 0 0 #9DA7FE;
}
#style-scroll::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #64B5F6;
    border: 4px solid transparent;
    background-clip: content-box;
}