@import "../../../_style/_variable.scss";
$cloudDuration: 0.14s;
$starDuration: 0.14s;
$fishDuration: 0.14s;
$rainbowDuration: 0.14s;
$delayItemStampsDuration: calc($bgStampsItemDurarion + $totalEffect + $stampsDuration + $delayStampsItemMove);  
.container-day-twenty-six-thirty {
    animation: stamps-bg $bgStampsItemDurarion linear 0s forwards,
    app-stamps-bg-hide $bgStampsItemHideDurarion linear calc($delayItemStampsDuration + $stampsItemMoveDuration + $addDelaybgStampsItemHide) forwards;
    .img-effect {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        opacity: 0;
    }
    .cloud {
        background-position: -190px -150.2px;
        z-index: 1;
        animation: show-cloud $cloudDuration linear $bgStampsItemDurarion forwards;
    }
    .star {
        background-position: 8px 56.5px;
        scale: 0.63;
        z-index: 2;
        animation: show-star $starDuration linear calc($bgStampsItemDurarion + $cloudDuration) forwards;
    }
    .fish {
        background-position: 104px 112px;
        z-index: 3;
        animation: show-fish $fishDuration linear calc($bgStampsItemDurarion + $cloudDuration + $starDuration) forwards;
    }
    .rainbow {
        background-position: -467.5px 25px;
        z-index:4;
        animation: show-rainbow $rainbowDuration linear calc($bgStampsItemDurarion + $cloudDuration + $starDuration + $fishDuration) forwards;
    }
}
.container-item-stamps-tap {
    .img-item-stamps-twenty-six-thirty-1 {
        z-index: 5;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-1 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-twenty-six-thirty-2 {
        z-index: 5;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-2 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-twenty-six-thirty-3 {
        z-index: 5;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-3 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-twenty-six-thirty-4 {
        z-index: 5;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-4 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-twenty-six-thirty-5 {
        z-index: 5;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-5 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
}

@keyframes show-cloud {
    to {
        background-position: -243.5px -150.2px;
        opacity: 1;
    }
}
@keyframes show-star {
    to {
        scale: 1;
        opacity: 1;
    }
}
@keyframes show-fish {
    to {
        background-position: 8.5px 112px;
        opacity: 1;
    }
}
@keyframes show-rainbow {
    to {
        opacity: 1;
    }
}