@import "../../../_style/_variable.scss";
$cloud1Duration: 0.2s;
$cloud2Duration: 0.2s;
$cloud3Duration: 0.2s;
$delayItemStampsDuration: calc($bgStampsItemDurarion + $totalEffect + $stampsDuration + $delayStampsItemMove);
.container-day-eleven-fifteen {
    animation: stamps-bg $bgStampsItemDurarion linear 0s forwards,
    app-stamps-bg-hide $bgStampsItemHideDurarion linear calc($delayItemStampsDuration + $stampsItemMoveDuration + $addDelaybgStampsItemHide) forwards;
    .img-cloud-1, .img-cloud-2, .img-cloud-3 {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-repeat: cover;
        opacity: 0;
    }
    .img-cloud-1 {
        background-position-y: 66px;
        animation: elevent-fifteen-show-cloud $cloud1Duration linear calc($bgStampsItemDurarion + $cloud3Duration + $cloud2Duration) forwards;
    }
    .img-cloud-2 {
        background-position-y: 208px;
        animation: elevent-fifteen-show-cloud $cloud2Duration linear calc($bgStampsItemDurarion + $cloud3Duration) forwards;
    }
    .img-cloud-3 {
        animation: elevent-fifteen-show-cloud $cloud3Duration linear $bgStampsItemDurarion forwards;
    }
}
.container-item-stamps-tap {
    .img-item-stamps-eleven-fifteen-1 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-1 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-eleven-fifteen-2 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-2 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-eleven-fifteen-3 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-3 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-eleven-fifteen-4 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-4 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-eleven-fifteen-5 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-5 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
}
@keyframes elevent-fifteen-show-cloud {
    to {
        opacity: 1;
    }
}