.container-page-poup {
    width: 100%;
    height: 100%;
}
.container-home-day {
    $cloudWaveDuration: 1.5s;
    $earthDuration: 0.5s;
    $layoutDelay: calc($cloudWaveDuration + $earthDuration);
    $layoutDuration: 0.5s;
    $paddingLayout: 24px;
    width: 100%;
    height: 100%;
    .content-home-day {
        position: relative;
        width: 100%;
        height: 100%;
        .cloud-wave {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            z-index: 103;
            animation: home-cloud-wave $cloudWaveDuration linear 0s forwards;
        }
        .cloud-wave-1 {
            background-image: url("../../assets/images/day_one_to_five/cloud.png");
        }
        .cloud-wave-2 {
            background-image: url("../../assets/images/day_six_to_ten/cloud.png");
        }
        .cloud-wave-3 {
            background-image: url("../../assets/images/day_eleven_to_fifteen/wave.png");
        }
        .cloud-wave-4 {
            background-position: center 60px;
            background-image: url("../../assets/images/day_sixteen_to_twenty/wave.png");
        }
        .cloud-wave-5 {
            background-position: -89px center;
            background-image: url("../../assets/images/day_twenty_one_to_twenty_five/wave.png");
        }
        .cloud-wave-6 {
            background-image: url("../../assets/images/day_twenty_six_to_thirty/cloud.png");
            background-size: calc(100% + 2px) 100%;
        }
        .container-layout {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 104;
            .container-knowledge-speaker, .container-guidance, .container-earth, .contaienr-item-day {
                padding: 0px $paddingLayout;
                width: 100%;
            }
            .container-knowledge-speaker {
                position: relative;
                width: 100%;
                height: 96px;
                padding-bottom: 10px;
                display: flex;
                align-items: flex-end;
                opacity: 0;
                animation: knowledge-speaker $layoutDuration linear $layoutDelay forwards;
                .container-knowledge {
                    position: relative;
                    width: 147.84px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    background-image: url("../../assets/icons/home_day_btn_trivia.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                }
                .container-speaker {
                    position: absolute;
                    right: $paddingLayout;
                    width: 49px;
                    height: 48px;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                }
                .speaker-on {
                    background-image: url("../../assets/icons/speaker_on.png");
                }
                .speaker-off {
                    background-image: url("../../assets/icons/speaker_off.png");
                }
            }
            .container-guidance {
                position: relative;
                height: 146px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                opacity: 0;
                // border: 1px solid red;
                background-repeat: no-repeat;
                background-position: center 0px;
                background-size: cover;
                animation: guidance $layoutDuration linear $layoutDelay forwards;
            }
            .title-1 {
                background-image: url("../../assets/images/day_one_to_five/title.png");
            }
            .title-2 {
                background-image: url("../../assets/images/day_six_to_ten/title.png");
            }
            .title-3 {
                background-image: url("../../assets/images/day_eleven_to_fifteen/title.png");
            }
            .title-4 {
                background-image: url("../../assets/images/day_sixteen_to_twenty/title.png");
            }
            .title-5 {
                background-image: url("../../assets/images/day_twenty_one_to_twenty_five/title.png");
            }
            .title-6 {
                background-image: url("../../assets/images/day_twenty_six_to_thirty/title.png");
            }
            .container-earth {
                height: 275px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-repeat: no-repeat;
                background-position: center 0px;
                background-size: cover;
                margin-top: -50px;
                z-index: -1;
                opacity: 0;
                animation: home-earth $earthDuration linear calc($cloudWaveDuration) forwards;
            }
            .earth-1 {
                background-size: 330px 275px;
                background-image: url("../../assets/images/day_one_to_five/earth.png");
            }
            .earth-2 {
                background-size: 272px 261px;
                background-position: calc(50px + 10px) calc(50% + 2px);
                background-image: url("../../assets/images/day_six_to_ten/earth.png");
            }
            .earth-3 {
                background-size: 272px 256px;
                background-position: calc(50px + 10px) calc(50% + 2px);
                background-image: url("../../assets/images/day_eleven_to_fifteen/earth.png");
            }
            .earth-4 {
                background-size: 269px 214px;
                background-position: calc(50px + 10px) calc(50% + 2px);
                background-image: url("../../assets/images/day_sixteen_to_twenty/earth.png");
            }
            .earth-5 {
                background-size: 292px 266px;
                background-image: url("../../assets/images/day_twenty_one_to_twenty_five/earth.png");
            }
            .earth-6 {
                background-size: 294px 271px;
                background-position: center calc(50% + 4px);
                background-image: url("../../assets/images/day_twenty_six_to_thirty/earth.png");
            }
            .container-item-stamps-descript {
                position: relative;
                opacity: 0;
                animation: item-day-descript $layoutDuration linear $layoutDelay forwards;
                .container-item-stamps {
                    margin-top: 20px;
                    width: 100%;
                    height: 97.5px;
                    display: flex;
                    justify-content: center;
                    .content-item-stamps {
                        width: 325.64px;
                        height: 97.95px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-image: url("../../assets/icons/home_day_bg_container_btn.png");
                        background-size: calc(100% - 2px) calc(100% - 2px);
                        background-position: center;
                        background-repeat: no-repeat;
                        .btn-stamps {
                            margin-left: 4px;
                            width: 52px;
                            height: 57px;
                            background-size: 52px 57px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-position: center center;
                            background-repeat: no-repeat;
                            color: rgb(19 103 145 / 0.7); //#136791;
                            font-size: 27px;
                            font-family: "shinmgopro-heavy";
                            cursor: pointer;
                            -webkit-user-select: none; /* Safari */
                            -ms-user-select: none; /* IE 10 and IE 11 */
                            user-select: none; /* Standard syntax */
                            &:first-child {
                                margin-left: 0px;
                            }
                        }
                    }
                }
                .container-descript {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    .container-soap, .container-dish-towel {
                        width: 300px;
                        height: 56px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 295px 56px;
                        display: flex;
                        padding-top: 11px;
                        justify-content: center;
                        color: #FFFFFF;
                        font-size: 20px;
                        font-family: "shinmgopro-heavy";
                        cursor: pointer;
                    }
                    .container-soap {
                        margin-top: 10.4px;
                        background-image: url("../../assets/icons/hone_day_descript_soap.png");
                    }
                    .container-dish-towel {
                        margin-top: 6.37px;
                        background-image: url("../../assets/icons/hone_day_descript_dish_towel.png");
                    }
                }
                .container-note {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    .content-note {
                        margin-top: 10px;
                        height: 24px;
                        border-bottom: 1px solid #9DA7FE;
                        color: #9DA7FE;
                        font-size: 16px;
                        font-family: "shinmgopro-heavy";
                        cursor: pointer;
                    }
                }
            }

        }
    }
    @keyframes home-cloud-wave {
        to {
            opacity: 1;
        }
    }
    @keyframes home-cloud2 {
        to {
            opacity: 1;
        }
    }
    @keyframes home-cloud3 {
        to {
            opacity: 1;
        }
    }
    @keyframes home-earth {
        from {
            opacity: 1;
        }
        to {
            margin-top: 0px;
            opacity: 1;
        }
    }
    @keyframes knowledge-speaker{
        from {
            opacity: 1;
            top: -12px;
        }
        to {
            top: 0px;
            opacity: 1;
        }
    }
    @keyframes guidance {
        from {
            opacity: 1;
            top: 28px;
        }
        to {
            opacity: 1;
            top: 0px;
        }
    }
    @keyframes item-day-descript {
        from {
            opacity: 1;
            top: 280px;
        }
        to {
            opacity: 1;
            top: 0px;
        }
    }
}
.remove-animation {
    img, div {
        animation-duration: 0s !important;
        animation-delay: 0s !important;
    }
}
.container-audio {
    width: 0px;
    height: 0px;
    opacity: 0;
    z-index: -1;
}