.container-splash {
    height: 812px;
    width: 100%;
    background-color: #90E0EF;
    .aniEarth {
        top: 40%;
        left: 25%;
        position: absolute;
        z-index: 1;
        img {
            position: relative;
            animation: stamps-earth-splash 5s ease-in-out 1s forwards;
            z-index: -1;
            opacity: 0;
        }
    }
}
@keyframes stamps-earth-splash  {
    0%   {opacity: 1; z-index: 1; left: 100px; top: 381px;}
    25%  {opacity: 1; left: -50px; top: 300px;}
    37%  {opacity: 1; left: 80px; top: 160px;}
    50%  {opacity: 1; left: 0px; top: 0px;}
    62%  {opacity: 1; top: -50px;}
    75%  {opacity: 1; top: 0px;}
    87%  {opacity: 1; top: -50px;}
    100% {opacity: 1; top: 0;}
}