.modal-knowledge-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  visibility: visible;
  opacity: 1;
  background: rgba(0, 0, 0, 0.86);
  .board {
    position: fixed;
    background-color: transparent;
    padding: 12px;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .btn-showKnowledge {
      border: none;
      background: transparent;
      float: left;
      margin-top: 0px;
      margin-left: -8px;
      img {
        width: 57px;
        height: 56px;
      }
    }
    .title {
      float: left;
      padding-top: 6px;
      font-size: 20px;
      font-family: "shinmgopro-heavy";
      color: #ffffff;
      // margin-left: -26px;
    }
    .btn-hide {
      border: none;
      background: transparent;
      float: right;
      margin-top: 0px;
      margin-right: -12px;
      img {
        width: 57px;
        height: 56px;
      }
    }
    .bg-modal {
      background-image: url("../../../assets/images/modal_knowledge/modalKnowledge.png");
      background-repeat: no-repeat;
      width: 328px;
      height: 420px;
      padding-top: 52px;
      .content-1 {
        text-align: center;
        width: 76%;
        height: 54px;
        z-index: 2;
        position: absolute;
        margin-left: 16px;
        background-color: #dee7ff;
        border: 1px solid #dee7ff;
        .content-bg-1 {
          background-color: #f0fffe;
          // border: 1px solid #f0fffe;
          border-radius: 10px;
          margin-top: 12px;
          margin-left: 9px;
          height: 32px;
          display: flex;
          // align-items: center;
          span {
            margin-top: 4px;
            font-family: "shinmgopro-heavy";
            color: #ff7dcc;
            font-size: 16px;
            flex: 1;
          }
        }
      }
      .content-bg {
        position: absolute;
        width: 74%;
        height: 135px;
        // height: 135px;
        margin-top: 52px;
        margin-left: 25px;
        z-index: 0;
        border-radius: 10px;
        background-color: #f0e3ff;
        // border: 1px solid #f0e3ff;
      }
      .content-2 {
        position: absolute;
        width: 80%;
        height: 168px;
        // height: 162px;
        padding-top: 10%;
        margin-top: 15px;
        margin-left: 25px;
        overflow-y: auto;
        z-index: 1;
        .content-text {
          padding: 15px;
          // text-align: justify;
          // width: calc(100% - 9px);
          width: 254px;
          span {
            font-family: "shinmgopro-heavy";
            font-size: 14px;
            color: #00396e;
          }
        }
      }
    }
  }
}

#style-scroll-knowledge::-webkit-scrollbar-track {
  border-radius: 100px;
  background-color: #b0bbff;
  border: 5px solid transparent;
  background-clip: content-box;
}
#style-scroll-knowledge::-webkit-scrollbar {
  width: 18px;
  border-radius: 100px;
  background-color: #dee7ff;
  box-shadow: inset 2px 0 0 #9da7fe;
}
#style-scroll-knowledge::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #64b5f6;
  border: 5px solid transparent;
  background-clip: content-box;
}
