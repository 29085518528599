@charset "UTF-8";
@import "./_variable.scss";
$marginTopStamp: 360px;
$heightStamp: 57px;
$widthStamp: 52px;
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    &:before {
        box-sizing: border-box;
    }
    &:after {
        box-sizing: border-box;
    }
}
body {
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
        Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
    font-size: 16px;
    background-color: #f0f1f2;
    color: #000000;
    height: 100%;
}
.app-hidden-modal {
    z-index: -1000000;
    opacity: 0;
    width: 0px !important;
    height: 0px !important;
    .modal-error, .modal-product, .modal-fade, .modal-know, .modal-knowledge-child {
        z-index: -100000000000000;
    }
}
.app-container-item-stamps-tap {
    width: 100%;
    height: 100%;
    top: 0px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app-container-stamps {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position-y: top;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
}
.app-img-item-stamps {
    opacity: 0;
    width: 0px;
    height: 0px;
}
@keyframes stamps-bg {
    to {
        opacity: 1;
    }
}
@keyframes app-stamps-bg-hide {
    to {
        opacity: 0;
    }
}
@keyframes app-item-stamps-show {
    to {
        width: 168px;
        height: 200px;
        opacity: 1;
    }
}
@keyframes app-item-stamps-move-1 {
    to {
        width: $widthStamp;
        height: $heightStamp;
        margin-top: $marginTopStamp;
        // margin-left: -223px;
        margin-left: -224px;
    }
}
@keyframes app-item-stamps-move-2 {
    to {
        width: $widthStamp;
        height: $heightStamp;
        margin-top: $marginTopStamp;
        margin-left: -112px;
    }
}
@keyframes app-item-stamps-move-3 {
    to {
        width: $widthStamp;
        height: $heightStamp;
        margin-top: $marginTopStamp;
        margin-left: 0px;
        // margin-left: 1px;
    }
}
@keyframes app-item-stamps-move-4 {
    to {
        width: $widthStamp;
        height: $heightStamp;
        margin-top: $marginTopStamp;
        // margin-left: 113px;
        margin-left: 112px;
    }
}
@keyframes app-item-stamps-move-5 {
    to {
        width: $widthStamp;
        height: $heightStamp;
        margin-top: $marginTopStamp;
        // margin-left: 225px;
        margin-left: 224px;
    }
}