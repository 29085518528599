#div-10-completed {
    height: 100%;
    width: 100%;
    overflow: hidden;
    #container-completed{
        position: relative;
        height: 100%;
        width: 100%;
        #sky-bg {
            position: absolute;
            animation: sky_falls .5s ease-in-out forwards;
        }
        #rainbow-bg {
            position: absolute;
            width: 556px;
            height: 626px;
            animation: rainbow_falls .5s ease-in-out forwards;
        }
        #blank-bg {
            position: absolute;
            width: 338px;
            height: 572px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            animation: blank_bg_rises .5s ease-in-out forwards;
        }
        #ribbon {
            position: absolute;
            width: 338px;
            height: 572px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            animation: ribbon_rises .5s ease-in-out forwards;
            z-index: 10;
        }
        #ribbon-text {
            position: absolute;
            width: 226px;
            height: 64px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            animation: ribbon_text_rises .5s ease-in-out forwards;
            z-index: 10;

        }
        .star{
            position: absolute;
            width: 23px;
            height: 36px;
            z-index: 9;
            top: 150px;
            animation: star_falls .5s .5s  ease-in-out forwards;
        }
        .star.align-left{
            left: 95px;
        }
        .star.align-right{
            right: 95px;
        }
        #you-made-it{
            background-image: url('../../../assets/images/10_completed/YouMadeIt.png');
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 144px 26px;
            position: absolute;
            width: 137px;
            height: 24px;
            z-index: 10;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: 209px;
            opacity: 0;
            animation: text_fade_in .5s 1s  ease-in-out forwards;
            // text-align: center;
            // color: #FF7B05;
            // font-size: 16px;
            // font-family: "shinmgopro-heavy";

        }
        .container-speaker {
            width: 48.81px;
            height: 48px;
            position: absolute;
            top: 30px;
            right: 28px;
            cursor: pointer;
            z-index: 1;
        }
        .grid-container {
            z-index: 11;
            position: absolute;
            left: 0;
            right: 0;
            width: 290px;
            height: 420px;
            margin-left: auto;
            margin-right: auto;
            // display: grid;
            // grid-template-columns: auto auto auto auto auto;
            padding: 0 10px;
            animation: grid_rises .5s ease-in-out forwards;

        }
        .grid-item{
            background-image: url('../../../assets/images/10_completed/list_stamps.png');
            background-repeat: no-repeat;
            background-size: 270px 422px;
            height: 100%;
            width: 100%;
        }
        // .grid-item{
        //     position: relative;
        //     height: 72px;
        //     display: flex;
        //     justify-content: center;
        // }
        .grid-item-img{
            width: 52px;
            height: 60px;
            position: absolute;
            cursor: pointer;
        }
    }
}
// animation part
@keyframes sky_falls {
    from{
        top: 140px;
        right: -279px;
    }
    to{
        top: 190px;
        right: -279px;
    }
}
@keyframes rainbow_falls {
    from{
        top:-50px;
        right: -73px;
    }
    to{
        top:0;
        right: -73px;
    }
}
@keyframes blank_bg_rises {
    from{
        top: 146px ;
    }
    to{
        top: 128px;
    }
}
@keyframes ribbon_rises {
    from{
        top: 142px ;
    }
    to{
        top: 124px;
    }
}
@keyframes ribbon_text_rises {
    from{
        top: 128px ;
    }
    to{
        top: 110px;
    }
}
@keyframes star_falls {
    from{
        top: 150px;
    }
    to{
        top: 196px;
    }
}
@keyframes text_fade_in {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes grid_rises {
    from{
        top: 258px ;
    }
    to{
        top: 240px;
    }
}