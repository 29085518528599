@import "../../../_style/_variable.scss";
$cloud1Duration: 0.2s;
$cloud2Duration: 0.2s;
$flowerLeftRightDuaration: 0.2s;
$delayItemStampsDuration: calc($bgStampsItemDurarion + $totalEffect + $stampsDuration + $delayStampsItemMove);
.container-day-twenty-tw-five {
    animation: stamps-bg $bgStampsItemDurarion linear 0s forwards,
    app-stamps-bg-hide $bgStampsItemHideDurarion linear calc($delayItemStampsDuration + $stampsItemMoveDuration + $addDelaybgStampsItemHide) forwards;
    .img-cloud-1, .img-cloud-2 {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center bottom;
        opacity: 0;
    }
    .img-cloud-1 {
        top: -140px;
        z-index: 1;
        animation: show-cloud-1 $cloud1Duration linear calc($bgStampsItemDurarion + $cloud2Duration + $flowerLeftRightDuaration + $flowerLeftRightDuaration) forwards;
    }
    .img-cloud-2 {
        top: 50px;
        background-image: url("../../../assets/images/day_twenty_one_to_twenty_five/stamps_cloud_2.png");
        animation: show-cloud-2 $cloud2Duration linear $bgStampsItemDurarion forwards;
    }
    .img-flower-left {
        position: absolute;
        left: -26px;
        width: 50%;
        height: 100%;
        opacity: 0;
        background-image: url("../../../assets/images/day_twenty_one_to_twenty_five/flower_left.png");
        background-repeat: no-repeat;
        background-position: left bottom;
        animation: show-flower-left $flowerLeftRightDuaration linear calc($bgStampsItemDurarion + $cloud2Duration + $flowerLeftRightDuaration) forwards;
    }
    .img-flower-right {
        position: absolute;
        right: -26px;
        width: 50%;
        height: 100%;
        opacity: 0;
        background-image: url("../../../assets/images/day_twenty_one_to_twenty_five/flower_right.png");
        background-repeat: no-repeat;
        background-position: right bottom;
        animation: show-flower-right $flowerLeftRightDuaration linear calc($bgStampsItemDurarion + $cloud1Duration) forwards;
    }
}
.container-item-stamps-tap {
    .img-item-stamps-twenty-one-twenty-five-1 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-1 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-twenty-one-twenty-five-2 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-2 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-twenty-one-twenty-five-3 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-3 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-twenty-one-twenty-five-4 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-4 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-twenty-one-twenty-five-5 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-5 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
}
@keyframes show-cloud-1 {
    from {
        opacity: 1;
    }
    to {
        top: -172px;
        opacity: 1;
    }
}
@keyframes show-cloud-2 {
    to {
        top: 0px;
        opacity: 1;
    }
}
@keyframes show-flower-left {
    to {
        left: 0px;
        opacity: 1;
    }
}
@keyframes show-flower-right {
    to {
        right: 0px;
        opacity: 1;
    }
}