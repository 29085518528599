@import "../../../_style/_variable.scss";
$pannelStampsDuration: 0.5s;
$delayItemStampsDuration: calc($bgStampsItemDurarion + $totalEffect + $stampsDuration + $delayStampsItemMove);
.container-day-one-five {
    animation: stamps-bg $bgStampsItemDurarion linear 0s forwards,
    app-stamps-bg-hide $bgStampsItemHideDurarion linear calc($delayItemStampsDuration + $stampsItemMoveDuration + $addDelaybgStampsItemHide) forwards;
    .img-pannel-left, .img-pannel-right {
        top: -175px;
        z-index: 1;
        animation: panel-show $pannelStampsDuration linear $bgStampsItemDurarion forwards;
    }
    .img-pannel-left {
        position: absolute;
        right: calc(50% - 15px);
        width: calc(50% + 15px);
        height: 100%;
        background-image: url("../../../assets/images/day_one_to_five/stamps_panner_left.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-position-y: 0px;
    }
    .img-pannel-right {
        position: absolute;
        left: calc(50% - 15px);
        width: calc(50% + 15px);
        height: 100%;
        background-image: url("../../../assets/images/day_one_to_five/stamps_panner_right.svg");
        background-repeat: no-repeat;
        background-position: left;
        background-position-y: 0px;
    }
    .bg-star {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-image: url("../../../assets/images/day_one_to_five/star.svg");
        background-position: -174px 140px;
        background-repeat: no-repeat;
        animation: star $stampsDuration linear calc($bgStampsItemDurarion + $pannelStampsDuration) forwards;
    }
}
.container-item-stamps-tap {
    .img-item-stamps-one-five-1 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-1 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-one-five-2 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-2 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-one-five-3 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-3 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-one-five-4 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-4 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-one-five-5 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-5 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
}
@keyframes panel-show {
    to {
        top: 0px;
    }
}
@keyframes star {
    to {
        opacity: 1;
    }
}