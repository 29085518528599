.modal-product {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    z-index: 100000;
    visibility: visible;
    opacity: 1;
    // transition: all 0.2s ease-in;
    background: rgba(0, 0, 0, 0.86);
    .board {
        position:fixed;
        background-color: transparent;
        padding: 12px;
        border-radius: 5px;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        .btn-showKnowledge {
            border: none;
            background: transparent;
            float: left;
            margin-top: -6px;
            margin-left: -9px;
        }
        .title {
            float: left;
            padding-left: 32%;
            padding-top: 5px;
            font-size: 20px;
            font-family: "shinmgopro-heavy";
            color: #FFFFFF;
        }
        .btn-hide {
          border: none;
          background: transparent;
          float: right;
          margin-top: 0px;
          margin-right: -12px;
          img {
            width: 57px;
            height: 56px;
          }
        }
        .bg-modal-1 {
            background-image: url('../../../assets/images/modal_product/bg-1.png');
            background-repeat: no-repeat;
            background-size: 100%;
        }
        .bg-modal-2 {
            background-image: url('../../../assets/images/modal_product/bg-2.png');
            background-repeat: no-repeat;
            background-size: 100%;
        }
        .bg-modal {
          width: 328px;
          height: 420px;
          padding-top: 52px;
          .content-img {
            text-align: center;
            margin-top: 17px;
            background-image: url('../../../assets/images/modal_product/bg-white.png'), url('../../../assets/images/modal_product/img-bg.png');
            background-repeat: no-repeat;
            background-position-y: 0px, 4px;
            background-position-x: center, center;
            height: 133px;
            img {
              width: 32%;
            }
          }
          
          .content {
            margin-left: 32px;
            margin-top: 22px;
            width: calc(100% - 56px);
            height: 158px;
            padding-top: 4px;
            padding-right: 12px;
            line-height: 21.5px;
            overflow-y: auto;
            .content-text {
                span {
                    font-family: "shinmgopro-heavy";
                    font-size: 14px;
                    color: #00396E;
                }
            }
          }
        }
    }
}
#style-scroll-product::-webkit-scrollbar-track {
  border-radius: 100px;
  background-color: #B0BBFF;
  border: 4px solid transparent;
  background-clip: content-box;
}
#style-scroll-product::-webkit-scrollbar {
  width: 16px;
  border-radius: 100px;
  background-color: #DEE7FF;
  box-shadow: inset 2px 0 0 #9DA7FE;
}
#style-scroll-product::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #64B5F6;
  border: 4px solid transparent;
  background-clip: content-box;
}