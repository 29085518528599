.container-home {
    width: 100%;
    height: 100%;
}
.container-30th-earth {
    width: 100%;
    width: 100%;
    z-index: 999999;
    top: 0px;
    position: fixed;
    .video-30thearth {
        width: 100%;
        width: 100%;
        display: block;
        object-fit: contain;
        aspect-ratio: auto;
    }
}
