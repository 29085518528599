#div-12_30th_Thanks {
    height: 100%;
    width: 100%;
    #container-12_30th_Thanks{
        position: relative;
        height: 100%;
        width: 100%;
        .bg-blue-light{
            position: absolute;
        }
        #flowers-land{
            width: 544px;
            height: 665px;
            position: absolute;
            bottom: -56px;
            left: -94px;
            animation: .5s fadeIn ease-in-out forwards, .5s flowers_rises ease-in-out forwards;
            z-index: 10;
        }
        #flowers-land-p{
            position: absolute;
            bottom: -56px;
            left: -281px;
            animation: 0.5s fadeIn ease-in-out forwards, 0.5s flowers_rises ease-in-out forwards;
            z-index: 10;
        }
        #earth{
            width: 417px;
            height: 417px;
            position: absolute;
            bottom: -42px;
            opacity: 0;
            left: -20px;
            animation: .5s fadeIn .15s ease-in-out forwards, .5s earth_rises .15s ease-in-out forwards;
            z-index: 3;
        }
        #snow{
            width: 390px;
            height: 186px;
            position: absolute;
            top: 230px;
            left: 0;
            opacity: 0;
            animation: .25s fadeIn .5s ease-in-out forwards;
            z-index: 3;
        }
        #top-clouds{
            width: 532px;
            height: 148px;
            position: absolute;
            top: -96px;
            left: -64px;
            opacity: 0;
            animation: .25s fadeIn .5s ease-in-out forwards, .25s clouds_falls .75s ease-in-out forwards;
            z-index: 1;
        }
        #rainbow{
            width: 332px;
            height: 154px;
            position: absolute;
            left: 22px;
            opacity: 0;
            top: 62px;
            animation: .25s fadeIn .75s ease-in-out forwards, .25s rainbow_rises .75s ease-in-out forwards;
            z-index: 1;
        }
        #sentence-main{
            width: 333px;
            height: 138px;
            position: absolute;
            left: 22px;
            opacity: 0;
            top: 151px;
            text-align: center;
            animation: .25s fadeIn .85s ease-in-out forwards, .25s sentence_main_rises .95s ease-in-out forwards;
            z-index: 1;
            // #phrase-earth-live{
            //     font-family: 'shinmgopro-heavy';
            //     font-size: 12px;
            //     color: #00CCFB;
            //     margin-bottom: 10px;
            // }
            #phrase-earth-live{
                width: 164px;
                height: 16px;
                margin-bottom: 10px;
            }
            #phrase-saving{
                width: 332.85px;
                height: 51.97px;
                margin-bottom: 5px;
            }
            #phrase-thankyou{
                width: 182.3px;
                height: 55.44px;
            }
        }
        #bg-blue{
            width: 327px;
            height: 212px;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            opacity: 0;
            top: 302px;
            animation: .25s fadeInQuarter 1s ease-in-out forwards, .25s bg_blue_rises 1.1s ease-in-out forwards;
            z-index: 2;
        }
        #sentence-sub{
            width: 280px;
            height: 92px;
            position: absolute;
            left: 50px;
            opacity: 0;
            top: 310px;
            animation: .25s fadeIn 1.1s ease-in-out forwards, .25s sentence_sub_rises 1.1s ease-in-out forwards;
            z-index: 2;
            // text-align: center;
            // line-height: 22px;
            // .sentence-sub{
            //     font-family: 'shinmgopro-heavy';
            //     font-size: 14px;
            // }
        }
        #container-banner{
            background-image: url("../../../assets/images/12_30th_thanks/banner.png");
            background-repeat: no-repeat;
            background-position: center bottom;
            width: 328px;
            height: 118px;
            position: absolute;
            left: 24px;
            opacity: 0;
            display: flex;
            justify-content: center;
            animation: .25s fadeInContainer 1.25s ease-in-out forwards, .25s date_container_rises 1.25s ease-in-out forwards;
            #text-banner{
                z-index: 12;
                font-family: 'shinmgopro-heavy';
                color: #FFFFFF;
                font-size: 18px;
                margin-top: 5px;
            }
        }
        #btn-back-to-star{
            background-image: url('../../../assets/images/12_30th_thanks/btn_back_to_start.svg');
            background-repeat: no-repeat;
            background-position: center bottom;
            color: #FFFFFF;
            font-family: "shinmgopro-heavy";
            font-size: 20px;
            position: absolute;
            z-index: 12;
            opacity: 0;
            animation: .25s fadeIn 1.25s ease-in-out forwards, .25s btn_btn_rises 1.25s ease-in-out forwards;
            width: 196px;
            height: 59px;
            left: 0;
            right: 0;
            margin: 0 auto;
            border: unset;
            background-color: unset;
        }
        #days-container{
            width: 328px;
            height: 104px;
            position: absolute;
            left: 24px;
            z-index: 12;
            display: grid;
            grid-template-columns: auto auto auto auto auto auto;
            align-items: center;
            padding: 0 10px;
            opacity: 0;
            animation: .25s fadeIn 1.25s ease-in-out forwards, .25s days_container_rises 1.25s ease-in-out forwards;

        }
        .grid-item{
            position: relative;
            display: flex;
            justify-content: center;
            .grid-item-img{
                position: absolute;
                z-index: 1;
            }
            .grid-round{
                cursor: pointer;
                z-index: 12;
                position: absolute;
                font-family: 'shinmgopro-heavy';
                color: #FFFFFF;
                font-size: 22px;
                padding-top: 4px;
            }
        }

    }
}
// animation part
@keyframes fadeIn {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@keyframes fadeInContainer {
    from{
        opacity: 0;
        z-index: -1;
    }
    to{
        opacity: 1;
        z-index: 11;
    }
}
@keyframes fadeInQuarter {
    from{
        opacity: 0;
    }
    to{
        opacity: .25;
    }
}
@keyframes flowers_rises {
    from{
        bottom: -56px;
    }
    to{
        bottom: -30px;

    }
}
@keyframes earth_rises {
    from{
        bottom: -42px;

    }
    to{
        bottom: 2px;
    }
}
@keyframes clouds_falls {
    from{
        top: -96px;
    }
    to{
        top: -78px;
    }
}
@keyframes rainbow_rises {
    from{
        top: 72px;
    }
    to{
        top: 52px;
    }
}
@keyframes sentence_main_rises {
    from{
        top: 151px;

    }
    to{
        top: 141px;
    }
}

@keyframes bg_blue_rises {
    from{
        top: 302px;
    }
    to{
        top: 282px;
    }
}
@keyframes sentence_sub_rises {
    from{
        top: 310px;
    }
    to{
        top: 290px;
    }
}
@keyframes date_container_rises {
    from{
        bottom: 63px;
    }
    to{
        bottom: 83px;
    }
}
@keyframes btn_btn_rises {
    from{
        bottom: 0px;
    }
    to{
        bottom: 20px;
    }
}
@keyframes days_container_rises {
    from{
        bottom: 84px;
    }
    to{
        bottom: 104px;

    }
}