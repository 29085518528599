@import "../../../_style/_variable.scss";
$wave1Duration: 0.1s;
$starDuration: 0.1s;
$flower1Duration: 0.1s;
$flower2Duration: 0.1s;
$flower3Duration: 0.1s;
$fishDuration: 0.1s;
$delayItemStampsDuration: calc($bgStampsItemDurarion + $totalEffect + $stampsDuration + $delayStampsItemMove);  
.container-day-sixteen-twenty {
    animation: stamps-bg $bgStampsItemDurarion linear 0s forwards,
    app-stamps-bg-hide $bgStampsItemHideDurarion linear calc($delayItemStampsDuration + $stampsItemMoveDuration + $addDelaybgStampsItemHide) forwards;
    .img-effect {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        opacity: 0;
    }
    .wave1 {
        background-position: 0px 0px;
        z-index: 1;
        animation: show-effect $wave1Duration linear $bgStampsItemDurarion forwards;
    }
    .stamps-day-wave1 {
        background-position: -516px 100%;
    }
    .stamps-day-3-wave1 {
        background-position: center center;
    }
    .star {
        background-position: 16.6px calc(100% - 10.6px);
        z-index: 2;
        animation: show-effect $starDuration linear calc($bgStampsItemDurarion + $wave1Duration) forwards;
    } 
    .flower1 {
        background-position: calc(50% - 12.5px) calc(100% - 104.6px);
        z-index: 3;
        animation: show-effect $flower1Duration linear calc($bgStampsItemDurarion + $wave1Duration + $starDuration) forwards;
    }
    .flower2 {
        background-position: 260px calc(100% + 5px);
        z-index: 4;
        animation: show-effect $flower1Duration linear calc($bgStampsItemDurarion + $wave1Duration + $starDuration + $flower1Duration) forwards;
    }
    .flower3 {
        background-position: 96.7px calc(100% + 5px);
        z-index: 5;
        animation: show-effect $flower1Duration linear calc($bgStampsItemDurarion + $wave1Duration + $starDuration + $flower1Duration + $flower2Duration) forwards;
    }
    .fish {
        background-position: 38.5px 517px;
        // background-position: 38.5px 368px;
        z-index: 6;
        animation: show-effect $flower1Duration linear calc($bgStampsItemDurarion + $wave1Duration + $starDuration + $flower1Duration + $flower2Duration + $flower3Duration) forwards;
    }
    .wave2 {
        background-position: 0px 0px;
        z-index: 7;
        animation: show-effect $stampsDuration linear calc($bgStampsItemDurarion + $wave1Duration + $starDuration + $flower1Duration + $flower2Duration + $flower3Duration + $fishDuration) forwards;
    }
    .stamps-day-1-wave2 {
        background-position: -101.4px calc(100% - 332.7px);
    }
}

.container-item-stamps-tap {
    .img-item-stamps-sixteen-twenty-1 {
        z-index: 8;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-1 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-sixteen-twenty-2 {
        z-index: 8;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-2 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-sixteen-twenty-3 {
        z-index: 8;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-3 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-sixteen-twenty-4 {
        z-index: 8;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-4 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-sixteen-twenty-5 {
        z-index: 8;
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-5 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
}

@keyframes show-effect {
    to {
        opacity: 1;
    }
}