.modal-know {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    z-index: 100000;
    visibility: visible;
    opacity: 1;
    background: rgba(0, 0, 0, 0.86);
    .board {
        position:fixed;
        background-color: transparent;
        padding: 12px;
        border-radius: 5px;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        .bg-modal {
          background-image: url('../../../assets/images/knowledge-list/bg.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 348px;
          height: 544px;
          padding-top: 173px;
          padding-left: 20px;
          .div-content {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            .div-parent {
                padding: 10px;
                height: 140px;
                .div-child-1 {
                    height: 82px;
                    width: 80px;
                    border-radius: 10px;
                    margin-bottom: 7px;
                    cursor: pointer;
                }
                .none-img {
                    background: url('../../../assets/images/knowledge-list/bg-orange.png'),#FFC100 ;
                    background-position:  center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                }
                .act-img {
                    border: 1px solid #BBCAFF;
                }
                .imgRound-1 {
                  background: url('../../../assets/images/knowledge-list/level-1.png'),#BBCAFF ;
                  background-position:  center;
                  background-repeat: no-repeat;
                  background-size: 80%;
                }
                .imgRound-2 {
                  background: url('../../../assets/images/knowledge-list/level-2.png'),#BBCAFF ;
                  background-position:  center;
                  background-repeat: no-repeat;
                  background-size: 80%;
                }
                .imgRound-3 {
                  background: url('../../../assets/images/knowledge-list/level-3.png'),#BBCAFF ;
                  background-position:  center;
                  background-repeat: no-repeat;
                  background-size: 80%;
                }
                .imgRound-4 {
                  background: url('../../../assets/images/knowledge-list/level-4.png'),#BBCAFF ;
                  background-position:  center;
                  background-repeat: no-repeat;
                  background-size: 80%;
                }
                .imgRound-5{
                  background: url('../../../assets/images/knowledge-list/level-5.png'),#BBCAFF ;
                  background-position:  center;
                  background-repeat: no-repeat;
                  background-size: 80%;
                }
                .imgRound-6{
                  background: url('../../../assets/images/knowledge-list/level-6.png'),#BBCAFF ;
                  background-position:  center;
                  background-repeat: no-repeat;
                  background-size: 80%;
                }
                .div-child-2 {
                    height: 29px;
                    width: 82px;
                    padding-top: 2px;
                    background-color: #9DA7FE;
                    border: 1px solid #9DA7FE;
                    border-radius: 14px;
                    text-align: center;
                    font-family: "shinmgopro-heavy";
                    font-size: 16px;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
            .btn-hide {
                border: none;
                background: transparent;
                margin-left: calc(50% - 38px);
                img {
                  width: 57px;
                  height: 56px;
                }
              }
          }
        }
    }
  }