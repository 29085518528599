.app-container-master {
    width: 100%;
    height: 100%;
    position: absolute;
    .app-container-page {
        width: 100%;
        height: 100%;
        display: flex;
        overflow-x: hidden;
        justify-content: center;
        .hocs-container-page {
            position: relative;
            width: 375px;
            height: 812px;
            overflow: hidden;
            transform: scale(1);
            transform-origin: center top;
        }
    }
}
