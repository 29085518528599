$bg1Duration: 1s;
$bg2Duration: 1s;
$rainbowDuration: 0.6s;
$titleDuration: 1s;
$handDuration: 1s;
$earthDuration: 1s;
$btnStartDuration: 0.3s;
.container-start {
    height: 100%;
    width: 100%;
    background-color: #00CCFB;
    position: relative;
    .bg-1 {
        position: absolute;
        width: calc(100% + 1px);
        height: 100%;
        opacity: 0;
        z-index: 2;
        background-image: url('../../../assets/images/start/cloud-1.png'); 
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-size: 100%;
        animation: stamps-bg-start $bg1Duration linear 0s forwards;
    }
    .bg-2 {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        background-image: url('../../../assets/images/start/cloud-2.png'); 
        background-repeat: no-repeat;
        background-size: 100%;
        animation: stamps-bg-start $bg2Duration linear $bg1Duration forwards;
    }
    .bg-3 {
        position: absolute;
        width: 100%;
        height: 836px;
        opacity: 0;
        z-index: 0;
        background-image: url('../../../assets/images/start/sky-login.png'), url('../../../assets/images/start/cloud-3.png');
        background-repeat: no-repeat;
        background-position: center top, center bottom;
        background-size: 100%;
        animation: stamps-bg-start $bg2Duration linear $bg1Duration forwards;
    }
    .bg-4 {
        position: absolute;
        margin-top: -210px;
        width: 100%;
        height: 836px;
        background-image: url('../../../assets/images/start/rainbow.png');
        background-repeat: no-repeat;
        background-position:  center top;
        background-size: 200%;
        animation: stamps-rain-start $rainbowDuration linear calc($bg1Duration + $bg2Duration) forwards;
    }
    .bg-5 {
        display: flex;
        justify-content: center;
        .imgTitle {
            position: absolute; 
            margin-top: 160px;
            width: 100%;
            height: 255px;
            padding-top: 40px;
            padding-left: 25%;
            display: flex;
            z-index: -1;
            background-image: url('../../../assets/images/start/title-login.png');
            background-repeat: no-repeat;
            background-position-x: center;
            background-size: 320px;
            animation: stamps-title-start $titleDuration linear calc($bg1Duration + $bg2Duration + $rainbowDuration) forwards; 
            // span {
            //     text-align: center;
            //     color: #FFFFFF;
            //     font-family: "shinmgopro-medium";
            //     font-size: 14px;
            // }
        }
        .bg-hand { 
            position: absolute;
            padding-top: 182px;
            bottom: 0;
            opacity: 0;
            z-index: 4;  
            width: 100%;
            animation: stamps-hand-start $handDuration linear calc($bg1Duration + $bg2Duration + $rainbowDuration + $titleDuration) forwards;
        }
        .imgEarth {
            position: absolute;
            bottom: 0;
            opacity: 0;
            z-index: 5;
            width: 100%;
            animation: stamps-earth-start $earthDuration linear calc($bg1Duration + $bg2Duration + $rainbowDuration + $titleDuration + $handDuration) forwards;
        }
        .bg-cloud {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: -1;
            background-image: url('../../../assets/images/start/cloud-left.png'), url('../../../assets/images/start/cloud-right.png');
            background-repeat: no-repeat;
            background-position: -72px 425px, 280px 425px;
            background-size: 40%;
            animation: stamps-btn-clound $earthDuration linear calc($bg1Duration + $bg2Duration + $rainbowDuration + $titleDuration + $handDuration) forwards;
        }
        .div-btn-start {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .btnStart {
                width: 144px;
                height: 56px;
                padding-bottom: 6px;
                text-align: center;
                border: none;
                background: transparent;
                color: #FFFFFF;
                font-family: "shinmgopro-heavy";
                font-size: 20px;
                opacity: 0;
                z-index: 6;
                background-image: url('../../../assets/icons/btn-start.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100%;
                animation: stamps-btn-start $btnStartDuration linear calc($bg1Duration + $bg2Duration + $rainbowDuration + $titleDuration + $handDuration + $earthDuration) forwards;
            }
        }
        .btn-sound {
            width: 48px;
            height: 48px;
            float: right;
            position: absolute;
            bottom: 0;
            right: 0;
            margin-bottom: 37px;
            margin-right: 36px;
            border: none;
            background: transparent;
            opacity: 0;
            z-index: 6;
            animation: stamps-btn-start $btnStartDuration ease-in-out calc($bg1Duration + $bg2Duration + $rainbowDuration + $titleDuration + $handDuration + $earthDuration) forwards;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}
@keyframes stamps-bg-start {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes stamps-rain-start  {
    from {
        top: 0px;
    }
    to {
        top: 210px;
    }
}
@keyframes stamps-title-start  {
    from {
        top: 15px;
        opacity: 0;
        z-index: 2;
    }
    to {
        top: -10px;
        opacity: 1;
        z-index: 3
    }
}
@keyframes stamps-hand-start {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes stamps-earth-start  {
    from {
        bottom: 30px;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}
@keyframes stamps-btn-start {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes stamps-btn-clound {
    from {
        opacity: 0;
        z-index: -1;
    }
    to {
        opacity: 1;
        z-index: 3;
    }
}