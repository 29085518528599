@import "../../../_style/_variable.scss";
$delayItemStampsDuration: calc($bgStampsItemDurarion + $totalEffect + $stampsDuration + $delayStampsItemMove);
.container-day-six-ten {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    overflow: hidden;
    animation: stamps-bg $bgStampsItemDurarion linear 0s forwards,
    app-stamps-bg-hide $bgStampsItemHideDurarion linear calc($delayItemStampsDuration + $stampsItemMoveDuration + $addDelaybgStampsItemHide) forwards;
    #container-stamp-reveal-6To10 {
        position: relative;
        height: 100%;
        width: 100%;
        #background{
            width: 375px;
            height: 812px;
            position: absolute;
            top: 0;
            left: 0;
        }
        #sunburst{
            width: 375px;
            height: 812px;
            position: absolute;
            visibility: hidden;
            top: -40px;
            animation: 0.25s appears calc($bgStampsItemDurarion) ease-in-out forwards, 0.25s sunburst-rises calc($bgStampsItemDurarion + 0.2s) ease-in-out forwards;
        }
        #land{
            width: 375px;
            height: 812px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            animation: 0.25s fade-in calc($bgStampsItemDurarion) ease-in-out forwards;
        }
        #flowers{
            width: 375px;
            height: 206px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -78px;
            opacity: 0;
            animation: 0.25s fade-in calc($bgStampsItemDurarion) ease-in-out forwards, 0.25s flowers-rise calc($bgStampsItemDurarion + 0.2s) ease-in-out forwards;
        }
        #bubble{
            width: 375px;
            height: 812px;
            position: absolute;
            top: 0;
            opacity: 0;
            transform: rotate(-3deg);
            animation: 0.25s bubbles-fade-in-rotate calc($bgStampsItemDurarion + 0.3s) ease-in-out forwards;
        }
        
    }
}
.container-item-stamps-tap {
    .img-item-stamps-six-ten-1 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-1 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-six-ten-2 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-2 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-six-ten-3 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-3 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-six-ten-4 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-4 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
    .img-item-stamps-six-ten-5 {
        animation: app-item-stamps-show $stampsDuration linear calc($bgStampsItemDurarion + $totalEffect) forwards,
        app-item-stamps-move-5 $stampsItemMoveDuration linear $delayItemStampsDuration forwards;
    }
}     
@keyframes fade-in {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@keyframes appears {
    from{
        visibility: hidden;
    }
    to{
        visibility:visible;
    }
}
@keyframes flowers-rise {
    from{
        bottom: -78px;
    }
    to{
        bottom: 0;
    }
}
@keyframes sunburst-rises {
    from{
        top: -40px;
    }
    to{
        top: 0;
    }
}
@keyframes bubbles-fade-in-rotate {
    from{
        opacity: 0;
        transform: rotate(-3deg);
    }
    to{
        transform: rotateX(0);
        opacity: 1;
    }
}